import logo from './logo512.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Koup.in</h1>
        <img src={logo} className="App-logo" alt="logo" />
        <p>
            We're delighted that you've discovered us, though we're not quite prepared for our debut to the world just yet
        </p>

      </header>
    </div>
  );
}

export default App;
